import React from 'react';
import './Banner.scss';

const Banner = () => {
  return (
    <div className='banner'>
      <p className='banner__text'>
        Opening on the 5th of december . Opening on the 5th of december .
        Opening on the 5th of december . Opening on the 5th of december .
        Opening on the 5th of december . Opening on the 5th of december .
        Opening on the 5th of december . Opening on the 5th of december .
        Opening on the 5th of december . Opening on the 5th of december .
      </p>
    </div>
  );
};

export default Banner;
